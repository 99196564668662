<template>
  <div class="home">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <span class="hidden">Товары</span>
      <div class="flex items-center">
        <el-select
          v-model="filters.mode"
          @change="handleModeChange"
          class="w-full"
          size="medium"
        >
          <el-option label="Все" :value="null"></el-option>
          <el-option
            v-for="(value, key) in $options.dampingOptions"
            :key="key"
            :label="value"
            :value="key"
          ></el-option>
        </el-select>
        <div class="flex items-center flex-shrink-0 ml-3">
          <el-input
            v-model="filters.query"
            @keyup.enter.native="searchProducts"
            @clear="searchProducts"
            clearable
            size="medium"
            class="w-full mr-2"
            placeholder="Поиск"
          ></el-input>
          <el-button @click="searchProducts" size="medium">Поиск</el-button>
        </div>
      </div>
    </h1>

    <el-table
      border
      size="small"
      v-loading="loading"
      :data="products"
      stripe
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column align="center" type="index" width="50" label="N">
      </el-table-column>
      <el-table-column prop="warehouse_id" label="Склад">
        <template slot-scope="{ row }">
          {{ getWarehouse(row.warehouse_id) }} <br />
          <el-button
            v-if="row.mode"
            size="small"
            @click="getOffers(row.kaspi_sku, row.warehouse_id)"
            >Офферы</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Название" width="150">
        <template slot-scope="scope">
          <router-link
            class="text-blue-600"
            target="_blank"
            :to="{
              name: 'Product',
              params: { id: scope.row.product_id },
            }"
          >
            [{{ scope.row.product_id }}]. {{ scope.row.name }}
            <template v-if="scope.row.brand">| {{ scope.row.brand }}</template>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU" width="150">
        <template slot-scope="{ row }">
          <a
            v-if="row.kaspi_sku"
            class="text-blue-600"
            target="_blank"
            :href="'https://kaspi.kz/shop/p/p-' + row.kaspi_sku + '/'"
          >
            {{ row.sku }} <br />
            {{ row.kaspi_sku }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="Маржа" width="90">
        <template slot-scope="{ row }">
          {{ Math.round(row.price - row.price * 0.125 - row.avg) }} <br />
          [{{ row.rest }}]
        </template>
      </el-table-column>
      <el-table-column prop="price" label="Цена" width="100">
        <template slot-scope="{ row }">
          <el-input v-model.number="row.price" size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="damping"
        label="Демпинг"
        align="center"
        width="100"
      >
        <template slot-scope="{ row }">
          <el-select v-model="row.mode" clearable size="mini">
            <el-option
              v-for="(value, key) in $options.dampingOptions"
              :key="key"
              :value="key"
              :label="value"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="min_price" label="Мин цена" width="100">
        <template slot-scope="{ row }">
          <el-input v-model.number="row.min_price" size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="step" label="Действия" align="center" width="120">
        <template slot-scope="{ row }">
          <el-button @click="() => save(row)" size="mini" type="primary" plain
            >Сохранить</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-model="filters.page"
      @current-change="handlePageChange"
      class="mt-3 mx-auto"
      background
    ></el-pagination>

    <el-drawer
      v-if="!!offerDetails"
      :visible="true"
      :before-close="close"
      size="50%"
    >
      <div class="px-5">
        <div class="text-right mb-3">
          {{ dateFormat(offerDetails.damped_at * 1000) }}
        </div>
        <el-table
          v-if="offerDetails.offers"
          border
          size="small"
          :data="offerDetails.offers"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column prop="merchantName" label="Магазин">
          </el-table-column>
          <el-table-column prop="price" label="Цена" align="right">
          </el-table-column>
        </el-table>

        <div class="font-semibold mt-5 mb-2.5">История демпинга</div>
        <el-table border size="mini" :data="dampingHistory" stripe>
          <el-table-column prop="created_at" label="Начало">
            <template slot-scope="{ row }">
              {{ dateFormat2(row.created_at * 1000) }}
            </template>
          </el-table-column>
          <el-table-column prop="finished_at" label="Конец">
            <template slot-scope="{ row }">
              <span v-if="row.finished_at">
                {{ dateFormat3(row.finished_at * 1000) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="mode" label="Режим"></el-table-column>
          <el-table-column prop="status_code" label="Статус"></el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getKaspiOnsaleProducts,
  getOfferDetails,
  saveKaspiOnsaleProducts,
} from "@/api/product";
import { getBranches } from "@/api/branch";
import { getShops } from "@/api/branch";
import { format } from "date-fns";

export default {
  name: "Home",
  dampingOptions: {
    low: "Low",
    mid: "Middle",
    high: "High",
    super_high: "Super high",
  },
  data: () => ({
    branches: [],
    products: [],
    shops: [],
    loading: false,
    filters: {
      shop_id: 2,
      page: 1,
      limit: 20,

      query: "",
      mode: null,
    },

    offerDetails: null,
    dampingHistory: null,
  }),
  computed: {
    page() {
      return this.$route.query.page;
    },
    mode() {
      return this.$route.query.mode;
    },
    query() {
      return this.$route.query.query;
    },
  },
  async created() {
    if (this.page) {
      this.filters.page = +this.page;
    }
    if (this.mode) {
      this.filters.mode = this.mode;
    }
    if (this.query) {
      this.filters.query = this.query;
    }

    await this.getProducts();
    this.branches = await getBranches();
    this.shops = await getShops();
  },
  methods: {
    async getProducts() {
      this.loading = true;
      const result = await getKaspiOnsaleProducts(this.filters);
      const { products } = result;
      this.products = products;
      this.loading = false;
    },
    async getOffers(kaspiSku, whId) {
      let cityId = whId === 1 ? "750000000" : "710000000";
      if (whId === 3) {
        cityId = "511010000";
      }
      const res = await getOfferDetails(kaspiSku, cityId);
      if (res.offer) {
        this.offerDetails = {
          offers: res.offer.offers,
          damped_at: res.damped_at,
        };
      }

      if (res.history) {
        this.dampingHistory = res.history;
      }
    },
    async save({ kp_id, min_price, mode, price, step, warehouse_id }) {
      try {
        await saveKaspiOnsaleProducts({
          kp_id,
          min_price,
          mode,
          price,
          step,
          warehouse_id,
        });
        this.$message.success("Success");
      } catch (e) {
        this.$message.error("Error", e.message);
      }
    },
    getWarehouse(whId) {
      return { 1: "Алматы", 2: "Астана", 3: "Шымкент" }[whId] || whId;
    },
    handlePageChange(page) {
      this.filters.page = page;
      this.changeUrlSearchParams();
      this.getProducts();
    },
    handleModeChange() {
      this.filters.page = 1;
      this.changeUrlSearchParams();
      this.getProducts();
    },
    searchProducts() {
      this.filters.page = 1;
      this.changeUrlSearchParams();
      this.getProducts();
    },
    tableRowClassName({ row }) {
      if (!row.price) return "warning";
      return "";
    },
    changeUrlSearchParams() {
      this.$router.push({
        query: {
          page: this.filters.page,
          mode: this.filters.mode,
          query: this.filters.query,
        },
      });
    },
    dateFormat(timestamp) {
      return format(timestamp + 5 * 60 * 60 * 1000, "dd MMM, yyyy HH:mm");
    },
    dateFormat2(timestamp) {
      return format(timestamp - 3 * 60 * 60 * 1000, "dd MMM, HH:mm:ss");
    },
    dateFormat3(timestamp) {
      return format(timestamp + 6 * 60 * 60 * 1000, "dd MMM, HH:mm:ss");
    },
    close() {
      this.offerDetails = null;
    },
  },
};
</script>

<style>
.el-table .warning,
.el-table .el-table__row--striped.warning {
  background: oldlace !important;
}

.el-table--striped .el-table__body tr.el-table__row--striped.warning td {
  background: oldlace !important;
}
</style>
