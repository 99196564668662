var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('h1',{staticClass:"font-semibold text-xl mb-5 flex justify-between items-center"},[_c('span',{staticClass:"hidden"},[_vm._v("Товары")]),_c('div',{staticClass:"flex items-center"},[_c('el-select',{staticClass:"w-full",attrs:{"size":"medium"},on:{"change":_vm.handleModeChange},model:{value:(_vm.filters.mode),callback:function ($$v) {_vm.$set(_vm.filters, "mode", $$v)},expression:"filters.mode"}},[_c('el-option',{attrs:{"label":"Все","value":null}}),_vm._l((_vm.$options.dampingOptions),function(value,key){return _c('el-option',{key:key,attrs:{"label":value,"value":key}})})],2),_c('div',{staticClass:"flex items-center flex-shrink-0 ml-3"},[_c('el-input',{staticClass:"w-full mr-2",attrs:{"clearable":"","size":"medium","placeholder":"Поиск"},on:{"clear":_vm.searchProducts},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchProducts($event)}},model:{value:(_vm.filters.query),callback:function ($$v) {_vm.$set(_vm.filters, "query", $$v)},expression:"filters.query"}}),_c('el-button',{attrs:{"size":"medium"},on:{"click":_vm.searchProducts}},[_vm._v("Поиск")])],1)],1)]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"border":"","size":"small","data":_vm.products,"stripe":"","row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"align":"center","type":"index","width":"50","label":"N"}}),_c('el-table-column',{attrs:{"prop":"warehouse_id","label":"Склад"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getWarehouse(row.warehouse_id))+" "),_c('br'),(row.mode)?_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.getOffers(row.kaspi_sku, row.warehouse_id)}}},[_vm._v("Офферы")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Название","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"text-blue-600",attrs:{"target":"_blank","to":{
            name: 'Product',
            params: { id: scope.row.product_id },
          }}},[_vm._v(" ["+_vm._s(scope.row.product_id)+"]. "+_vm._s(scope.row.name)+" "),(scope.row.brand)?[_vm._v("| "+_vm._s(scope.row.brand))]:_vm._e()],2)]}}])}),_c('el-table-column',{attrs:{"prop":"sku","label":"SKU","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.kaspi_sku)?_c('a',{staticClass:"text-blue-600",attrs:{"target":"_blank","href":'https://kaspi.kz/shop/p/p-' + row.kaspi_sku + '/'}},[_vm._v(" "+_vm._s(row.sku)+" "),_c('br'),_vm._v(" "+_vm._s(row.kaspi_sku)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"Маржа","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(Math.round(row.price - row.price * 0.125 - row.avg))+" "),_c('br'),_vm._v(" ["+_vm._s(row.rest)+"] ")]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"Цена","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini"},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", _vm._n($$v))},expression:"row.price"}})]}}])}),_c('el-table-column',{attrs:{"prop":"damping","label":"Демпинг","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-select',{attrs:{"clearable":"","size":"mini"},model:{value:(row.mode),callback:function ($$v) {_vm.$set(row, "mode", $$v)},expression:"row.mode"}},_vm._l((_vm.$options.dampingOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"min_price","label":"Мин цена","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini"},model:{value:(row.min_price),callback:function ($$v) {_vm.$set(row, "min_price", _vm._n($$v))},expression:"row.min_price"}})]}}])}),_c('el-table-column',{attrs:{"prop":"step","label":"Действия","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":function () { return _vm.save(row); }}},[_vm._v("Сохранить")])]}}])})],1),_c('el-pagination',{staticClass:"mt-3 mx-auto",attrs:{"background":""},on:{"current-change":_vm.handlePageChange},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}}),(!!_vm.offerDetails)?_c('el-drawer',{attrs:{"visible":true,"before-close":_vm.close,"size":"50%"}},[_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"text-right mb-3"},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.offerDetails.damped_at * 1000))+" ")]),(_vm.offerDetails.offers)?_c('el-table',{attrs:{"border":"","size":"small","data":_vm.offerDetails.offers}},[_c('el-table-column',{attrs:{"type":"index","width":"50","align":"center"}}),_c('el-table-column',{attrs:{"prop":"merchantName","label":"Магазин"}}),_c('el-table-column',{attrs:{"prop":"price","label":"Цена","align":"right"}})],1):_vm._e(),_c('div',{staticClass:"font-semibold mt-5 mb-2.5"},[_vm._v("История демпинга")]),_c('el-table',{attrs:{"border":"","size":"mini","data":_vm.dampingHistory,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"created_at","label":"Начало"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dateFormat2(row.created_at * 1000))+" ")]}}],null,false,2895514778)}),_c('el-table-column',{attrs:{"prop":"finished_at","label":"Конец"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.finished_at)?_c('span',[_vm._v(" "+_vm._s(_vm.dateFormat3(row.finished_at * 1000))+" ")]):_vm._e()]}}],null,false,620132145)}),_c('el-table-column',{attrs:{"prop":"mode","label":"Режим"}}),_c('el-table-column',{attrs:{"prop":"status_code","label":"Статус"}})],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }